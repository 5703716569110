<template>
	<div>
		<div class="row" v-if="!showPaymentMethod">
			<div class="col-12">
				<p>Please enter the amount you are paying for each traveller.</p>

			</div>

			<ul class="list-group list-group-flush">
				<li class="list-group-item" v-for="traveller in activeOrderTravellers" :key="traveller.id">
					<div class="row">
						<div class="col-8">
							{{ traveller.fname?.toUpperCase() }} {{ traveller.lname?.toUpperCase() }}
						</div>
						<div class="col-4">
							<input class="form-control" type="number" v-model.number="paymentAmount[traveller.id]"
								@input="getPaymentAmount" />
						</div>
					</div>
				</li>
			</ul>
			<div class="col-12 my-3 d-grid">
				<button class="btn btn-dark" @click="btnContinueClick" :disabled="!isFormValid">
					Continue to pay {{ totalPaymentAmount < 1 ? "---" : formatCurrency(totalPaymentAmount) }} </button>
						<div class="text-danger my-2" v-if="isPayingTooMuch">The amount entered is greater than the amount
							owed. Please enter a different amount.</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-12 d-grid">
				<p class="small">A payment processing fee is applied to all credit card payments on our website. If you do
					not wish to be charged this fee, please use Zelle Quickpay to submit your payment.</p>
				<PaymentMethod ref="paymentMethodRef" :activeOrder="activeOrder" :key="paymentMethodComponentKey" />
				<div class="row my-3">
					<div class="col-12 d-grid mb-5">
						<button class="btn btn-dark my-3" :disabled="isReservationFormBusy" @click="btnPayNowClick"
							v-if="selectedPaymentMethod == 'credit_card'">
							<div class="spinner-border" role="status" v-if="isReservationFormBusy">
								<span class="visually-hidden">Loading...</span>
							</div>
							<span v-else>Pay {{ formatCurrency(totalPaymentAmount) }} now </span>
						</button>
						<button class="btn btn-link text-dark" @click="btnContinueClick">
							Go back
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PaymentMethod from "../common/PaymentMethod.vue";
import { formatCurrency } from "../../utils";
export default {
	name: "add-payment-amount",
	components: {
		PaymentMethod,
	},
	props: {
		activeOrderTravellers: Array,
		activeOrder: Object,
	},
	setup() { },
	computed: {
		totalTravellers() {
			return this.$store.state.totalTravellers;
		},
		totalPayment() {
			const paymentAmount = this.paymentAmount.reduce((total, num) => Number(total + num), 0);
			this.$store.commit("setPaymentAmount", paymentAmount)
			return paymentAmount;
		},
		isReservationFormBusy() {
			return this.$store.state.isReservationFormBusy;
		},
		selectedPaymentMethod() {
			return this.$store.state.selectedPaymentMethod;
		},
		isFormValid() {
			if (this.totalPaymentAmount <= 0) {
				return false;
			}

			for (let i = 0; i < this.activeOrderTravellers.length; i++) {
				const balance = parseFloat(this.activeOrderTravellers[i].order_total - this.activeOrderTravellers[i].order_discount - this.activeOrderTravellers[i].order_payments);
				if (balance < this.paymentAmount[this.activeOrderTravellers[i].id]) {
					this.isPayingTooMuch = true;
					return false;
				} else {
					this.isPayingTooMuch = false;
				}
			}

			return true;
		}
	},
	data() {
		return {
			formatCurrency,
			isBusy: false,
			totalPaymentAmount: 0,
			showPaymentMethod: false,
			paymentAmount: [],
			paymentMethod: null,
			paymentMethodComponentKey: null,
			isPayingTooMuch: false,
		}
	},
	methods: {
		selectPaymentMethod() {
			console.log(this.paymentMethod);
		},
		btnContinueClick() {
			this.showPaymentMethod = !this.showPaymentMethod;
			this.paymentMethodComponentKey = Date.now();
		},
		getPaymentAmount() {
			this.totalPaymentAmount = Object.keys(this.paymentAmount).reduce((sum, key) => parseFloat(sum + this.paymentAmount[key]), 0);
			this.$store.commit("setPaymentAmount", this.totalPaymentAmount);
			this.$store.dispatch("getStripeAmount", { paymentAmount: this.totalPaymentAmount });
		},
		btnPayNowClick() {
			const orderId = this.activeOrderTravellers[0]?.order_id;
			this.$refs.paymentMethodRef.submitAddPaymentForm({ orderId: orderId, paymentAmount: this.paymentAmount });
		},
	},
	mounted() {

	}

}
</script>
