<template>
	<div class="p-lg-2" id="pageTop">
		<div v-if="Object.keys(activeOrder).length > 0">
			<div class="row mb-1">
				<div class="" align="right">
					<button class="btn btn-link"><i class="bi bi-printer"></i> Print</button>
				</div>
				<div class="alert small text-center" :class="[isPaymentOverdue ? 'alert-danger' : 'alert-info']"
					v-if="getNextPaymentAmount > 0">
					<span v-if="isPaymentOverdue">There is an outstanding balance that requires your attention. Kindly
						submit a
						payment or contact accounting@socaislands.com to maintain your booking.<br /></span>
					<span class="fw-bold">
						Next Payment Amount:
						{{ formatCurrency(getNextPaymentAmount) }}</span>
					<span class="d-none d-lg-inline"> / </span><br class="d-lg-none" />
					<span class="fw-bold">
						Next Due Date:
						{{ formatDate(getNextPaymentDate, "MMM dd, yyyy") }}</span>
					<button class="btn" data-bs-toggle="offcanvas" data-bs-target="#addPaymentCanvas"><i
							class="bi bi-credit-card"></i> Make a payment</button>
				</div>
			</div>
			<div class="row">
				<div class="col-12 col-lg-4 mb-4">
					<ul class="list-group sticky-lg-top">
						<li class="list-group-item bg-dark border-dark active">
							<h5 class=""><i class="fas fa-clipboard-list"></i> Trip Summary</h5>
						</li>
						<li class="list-group-item">
							<div class="col-12 pt-2">
								<p>Ref#: {{ activeOrder.ref }}</p>
								<p>
									Booking Date: {{ formatDate(activeOrder.order_date, "MMM dd, yyyy") }}
								</p>
								<h6>{{ activeOrder.trip_event.name }}</h6>
								<p class="small text-secondary">
									{{ formatDate(activeOrder.trip_date.start_date) }} to
									{{ formatDate(activeOrder.trip_date.end_date) }}
								</p>
							</div>
							<div class="col-12">
								<h6>Accommodation</h6>
								<p>
									{{ activeOrder.accommodation.name }}
									<br /><span class="small text-secondary">{{ activeOrder.accommodation.notes }}
									</span>
								</p>
							</div>
							<div class="col-12">
								<h6>Transportation</h6>
								<p>
									{{ activeOrder.transportation.name }}
								</p>
							</div>
						</li>
					</ul>
				</div>
				<div class="col-12 col-lg-4 mb-4">
					<ul class="list-group">
						<li class="list-group-item bg-dark border-dark active">
							<h5 class=""><i class="fas fa-users"></i> Traveller(s) Information</h5>
						</li>
						<li class="list-group-item">
							<BookingTravellers :activeOrderTravellers="activeOrderTravellers" />
						</li>
					</ul>
				</div>
				<div class="col-12 col-lg-4 mb-5">
					<ul class="list-group">
						<li class="list-group-item bg-dark border-dark active">
							<h5 class=""><i class="fas fa-file-invoice-dollar"></i> Payment Summary</h5>
						</li>
						<li class="list-group-item">
							<div class="row">
								<div class="col">
									<span>Total trip cost:</span>
								</div>
								<div class="col-auto" align="right">
									{{ formatCurrency(activeOrder.order_total) }}
								</div>
							</div>
							<div class="row">
								<div class="col">
									<span>Total payments:</span>
								</div>
								<div class="col-auto" align="right">
									{{ formatCurrency(getTripPayments) }}
								</div>
							</div>
							<div class="row text-danger border-top. border-3. border-dark">
								<div class="col">
									<span>Balance:</span>
								</div>
								<div class="col-auto" align="right">
									{{ formatCurrency(getTripBalance) }}
								</div>
							</div>
							<div class="my-4 d-grid">
								<button class="btn btn-sm. btn-lg. btn-outline-dark btn-dark." data-bs-toggle="offcanvas"
									data-bs-target="#addPaymentCanvas" aria-controls="addPaymentCanvas" v-if="activeOrder.order_total >
										activeOrder.order_discount + activeOrder.order_payments
										">
									Make a payment
								</button>
							</div>
						</li>
						<li class="list-group-item">
							<span class="fw-bold">Payment History</span>
							<BookingPayments :activeOrderPayments="activeOrderPayments" />
						</li>
					</ul>
				</div>
			</div>
			<div class="w-100 mb-5"></div>
			<div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="addPaymentCanvas"
				aria-labelledby="addPaymentCanvasLabel">
				<div class="offcanvas-header">
					<h6 id="addPaymentCanvasLabel">Add New Payment</h6>
					<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
						aria-label="Close"></button>
				</div>
				<div class="offcanvas-body">
					<AddPaymentAmount :activeOrderTravellers="activeOrderTravellers" :activeOrder="activeOrder" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { formatDate, formatCurrency } from "../../utils";
import AddPaymentAmount from "./AddPaymentAmount.vue";
import BookingPayments from "../bookings/BookingPayments.vue";
import BookingTravellers from "./BookingTravellers.vue";
import moment from "moment";

export default {
	name: "my-bookings-details",
	components: { AddPaymentAmount, BookingPayments, BookingTravellers },
	props: {
		activeOrder: Object,
		activeOrderPayments: Array,
		activeOrderTravellers: Array,
	},
	setup() { },
	computed: {
		activeEvent() {
			return this.$store.state.eventsList;
		},
		paymentAmount() {
			return this.$store.state.paymentAmount;
		},
		getTripPayments() {
			return this.activeOrderPayments.reduce(
				(previousValue, currentValue) => previousValue + currentValue.amount,
				0
			);
		},
		getTripBalance() {
			const orderTotal = this.activeOrder.order_total;
			const orderDiscount = this.activeOrder.order_discount;
			const payments = this.getTripPayments;
			return parseFloat(orderTotal - orderDiscount - payments);
		},
		getPaymentPercentage() {
			return this.getTripPayments / this.getOrderTotal;
		},
		getOrderTotal() {
			return parseFloat(this.activeOrder.order_total - this.activeOrder.order_discount);
		},
		getNextPaymentAmount() {
			const todaysDate = moment();
			const bookingDate = moment(this.activeOrder.order_date);
			const tripDueDate = moment(this.activeOrder.tripStartDate).subtract(60, "days").valueOf();
			const dueDateDiff = todaysDate.diff(bookingDate, "days");
			const orderTotal = this.getOrderTotal;
			const payments = this.getTripPayments;
			const paymentPercentage = this.getPaymentPercentage;
			let nextPaymentAmount = parseFloat(orderTotal - payments);
			if (paymentPercentage === 0 && dueDateDiff < 30) {
				nextPaymentAmount = parseFloat(orderTotal * 0.15);
			} else if (paymentPercentage < 0.5 && tripDueDate > 60) {
				nextPaymentAmount = parseFloat(orderTotal * (0.5 - paymentPercentage));
			}
			return nextPaymentAmount;
		},
		getNextPaymentDate() {
			const paymentPercentage = this.getPaymentPercentage;
			const todaysDate = moment();
			const bookingDate = moment(this.activeOrder.order_date);
			const tripStartDate = moment(this.activeOrder.trip_date.start_date);
			const bookingDateDiff = todaysDate.diff(bookingDate, "days");
			const tripDateDiff = tripStartDate.diff(todaysDate, "days");
			if (paymentPercentage === 0 && bookingDateDiff < 30) {
				return bookingDate.add(7, "days").valueOf();
			}
			if (bookingDateDiff <= 30 || (bookingDateDiff > 30 && paymentPercentage < 0.5)) {
				return bookingDate.add(30, "days").valueOf();
			}
			if (tripDateDiff < 60 && this.getTripBalance > 0) {
				if (this.activeOrder.trip_date?.payment_deadline) {
					return this.activeOrder.trip_date?.payment_deadline;
				}
				return tripStartDate.subtract(60, "days").valueOf();
			}
			if (this.activeOrder.trip_date?.payment_deadline) {
				return this.activeOrder.trip_date?.payment_deadline;
			}
			return tripStartDate.subtract(60, "days").valueOf();
		},
		isPaymentOverdue() {
			const todaysDate = moment();
			const nextPaymentDate = moment(this.getNextPaymentDate);
			const nextPaymentDateDiff = nextPaymentDate.diff(todaysDate, "days");
			if (nextPaymentDateDiff < 0) {
				return true;
			}
			return false;
		},
	},
	data() {
		return {
			formatCurrency,
			formatDate,
			isBusy: false,
			orderDetailsComponentKey: null,
		};
	},
	methods: {},
	mounted() {
		this.$store.commit("setPageTitle", "Bookings");
	},
};
</script>
