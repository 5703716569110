<template>
	<div class="p-2" id="pageTop">
		<ListMyBookings />
	</div>
</template>

<script>
import ListMyBookings from "../components/bookings/ListMyBookings.vue"
	export default {
		name: "list-my-bookings",
		components: { ListMyBookings },
		computed:{
			
		},
		data(){
			return{
				isBusy: false,
			}
		},
		methods:{
			
		},
		mounted(){
			this.$store.commit("setPageTitle", "My Bookings")
        }

	}
</script>
