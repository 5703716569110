<template>
	<div class="" id="pageTop">
		<div class="row my-3">
			<div class="h3">My Bookings</div>
		</div>
		<div class="row">
			<div class="col-md-8 mb-3">
				<input class="form-control" placeholder="Search..." />
			</div>
			<div class="col-8 col-sm-7 col-md-4 mb-3 order-first order-md-last">
				<select class="form-select" v-model="visibleTrips">
					<option value="upcoming-trips">Upcoming trips</option>
					<option value="past-trips">Past trips</option>
				</select>
			</div>
		</div>
		<div class="row">
			<div class="text-dark">
				{{ searchResultsSummary }}
			</div>
		</div>
		<div class="row my-4" v-if="Array.isArray(myOrders)">
			<div class="row my-5 text-secondary" align="center"
				v-if="Array.isArray(filteredOrders) && filteredOrders.length < 1">
				<em><i class="far fa-calendar"></i> No bookings found...</em>
			</div>
			<div class="col-12 mb-4 d-none d-lg-block">
				<table class="table table-bordered" v-if="filteredOrders.length > 0">
					<thead>
						<tr>
							<th>Booking Ref#</th>
							<th>Trip Details</th>
							<th>Travellers</th>
							<th>Trip Cost (USD)</th>
							<th class="text-danger">Balance (USD)</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="order in filteredOrders" :key="order.id">
							<td>
								<p>
									{{ order.ref }}
								</p>
							</td>
							<td>
								<p>
									{{ order.trip_event.name }}
									<br />
									<span class="text-muted small">{{ formatDate(order.trip_date?.start_date) }} to
										{{ formatDate(order.trip_date?.end_date) }}</span>
								</p>
							</td>
							<td>
								<p>
									{{ order.travellers.join(", ") }}
								</p>
							</td>
							<td>
								<p>
									{{
										formatCurrency(order.order_total - order.order_discount)
									}}
								</p>
							</td>
							<td class="text-danger">
								<p>
									{{
										formatCurrency(order.order_total - order.order_discount - order.order_payments)
									}}
								</p>
							</td>
							<td v-if="order.order_status === 'in-review'">
								<span class="small text-danger">
									Your order is currently being reviewed <br />by a member of the Soca Islands team.
								</span>
							</td>
							<td v-else>
								<button class="btn btn-dark col-9 me-3" data-bs-toggle="modal"
									data-bs-target="#listMyBookingsModal" @click="btnViewClick(order)">
									View
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="col-12 col-sm-6 mb-4 d-lg-none" v-for="order in filteredOrders" :key="order.id">
				<ul class="list-group">
					<li class="list-group-item">
						<div class="row my-3">
							<div class="col-12" align="left">
								<p>
									<span><small class="small text-secondary">Ref#:</small><br />
										{{ order.ref }}</span>
								</p>
								<p>
									<span><small class="small text-secondary">Booking date:</small><br />
										{{ formatDate(order.order_date) }}</span>
								</p>
								<p>
									<span><small class="small text-secondary">Trip:</small><br />
										{{ order.trip_event.name }}</span>
									<br /><span class="small">{{ formatDate(order.trip_date.start_date) }} to
										{{ formatDate(order.trip_date.end_date) }}</span>
								</p>
								<p>
									<span><small class="small text-secondary">Travellers:</small></span><br />{{
										order.travellers.join(", ") }}
								</p>
								<div class="row">
									<div class="col">Total trip cost (USD):
									</div>
									<div class="col-auto text-end">{{
										formatCurrency(order.order_total)
									}}
									</div>
								</div>
								<div class="row">
									<div class="col">Discount (USD):
									</div>
									<div class="col-auto text-end">({{
										formatCurrency(order.order_discount)
									}})
									</div>
								</div>
								<div class="row">
									<div class="col">Payments (USD):
									</div>
									<div class="col-auto text-end">{{
										formatCurrency(order.order_payments)
									}}
									</div>
								</div>
								<div class="row border-top my-1 py-1 text-danger">
									<div class="col">Balance (USD):
									</div>
									<div class="col-auto text-end">{{
										formatCurrency(order.order_total - order.order_discount -
											order.order_payments)
									}}
									</div>
								</div>
							</div>
							<div class="col-12 small text-danger" align="left" v-if="order.order_status === 'in-review'">
								Your order is currently being reviewed by a member of the Soca Islands team.
							</div>
							<div class="col-12" align="left" v-else>
								<button class="btn btn-dark my-3 col-9 me-3" data-bs-toggle="modal"
									data-bs-target="#listMyBookingsModal" @click="btnViewClick(order)">
									View
								</button>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<div class="row my-4" v-else>
			<ul class="list-group mb-4" v-for="index in 3" :key="index">
				<li class="list-group-item">
					<div class="col-12 mb-4">
						<div class="row small">
							<div class="col-7 col-lg-3 my-1">
								<Skeletor height="25" pill />
							</div>
							<div class="col-7 col-lg-5 my-1">
								<Skeletor height="25" pill />
							</div>
							<div class="col-9 col-lg-2 my-1">
								<Skeletor height="25" pill />
							</div>
							<div class="col-12 col-lg-2 d-grid my-1">
								<Skeletor height="25" pill />
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<div class="modal fade" id="listMyBookingsModal" tabindex="-1" aria-labelledby="listMyBookingsModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-scrollable modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="listMyBookingsModalLabel">Trip Details</h5>
						<button type="button" class="btn-close m-1" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body py-0">
						<MyBookingsDetails :activeOrder="activeOrder" :activeOrderTravellers="activeOrderTravellers"
							:activeOrderPayments="activeOrderPayments" :key="bookingDetailsComponentKey" />
					</div>
					<!-- <div class="modal-footer">
                        <div class="col-12 d-grid">
                            <button
                                class="btn btn-lg btn-dark"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#addPaymentCanvas"
                                aria-controls="addPaymentCanvas"
                                v-if="activeOrder.order_total > activeOrder.order_discount + activeOrder.order_payments"
                            >
                                Make a payment
                            </button>
                        </div>
                    </div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { collection, collectionGroup, where, query, onSnapshot } from "firebase/firestore";
import { db, collections, subCollections } from "../../firebaseConfig";
import { formatDate, formatCurrency } from "../../utils";
import MyBookingsDetails from "./MyBookingsDetails.vue";
export default {
	name: "list-my-bookings",
	components: { MyBookingsDetails },
	computed: {
		searchResultsSummary() {
			const visibleTrips = this.visibleTrips;
			const prefix =
				visibleTrips == "upcoming-trips" ? "Upcoming" : visibleTrips == "past-trips" ? "Past" : "All";
			return `${prefix} trips (${this.filteredOrders.length})`;
		},
		myOrders() {
			return this.$store.state.myOrders;
		},
		filteredOrders() {
			const myOrders = Array.isArray(this.myOrders) ? this.myOrders : [];
			const visibleTrips = this.visibleTrips;
			const currentDate = Date.now() / 1000;
			switch (visibleTrips) {
				case "upcoming-trips":
					return myOrders.filter((order) => order.trip_date.start_date > currentDate);
					break;
				case "past-trips":
					console.log("past");
					return myOrders.filter((order) => order.trip_date.start_date < currentDate);
					break;
				default:
					return myOrders;
			}
		},
	},
	data() {
		return {
			formatCurrency,
			formatDate,
			isBusy: false,
			bookingDetailsComponentKey: null,
			activeOrder: {},
			activeOrderTravellers: [],
			activeOrderPayments: [],
			visibleTrips: "upcoming-trips",
		};
	},
	methods: {
		async btnViewClick(order) {
			const orderId = order.id;
			this.activeOrder = order;
			this.activeOrderTravellers = await this.getActiveOrderTravellers(orderId);
			this.activeOrderPayments = await this.getActiveOrderPayments(orderId);
		},
		getActiveOrderTravellers(orderId) {
			const travellers = [];
			const travellersRef = collection(db, `${collections.ORDERS}/${orderId}/${subCollections.TRAVELLERS}`);
			onSnapshot(
				travellersRef,
				(querySnapshot) => {
					travellers.length = 0;
					querySnapshot.forEach((doc) => {
						travellers.push({ id: doc.id, ...doc.data() });
					});
					this.bookingDetailsComponentKey = Date.now();
				},
				(error) => {
					console.log(error);
				}
			);
			return travellers;
		},
		getActiveOrderPayments(orderId) {
			const payments = [];
			const paymentsRef = query(
				collectionGroup(db, subCollections.JOURNAL_ENTRIES),
				where("order_id", "==", orderId)
			);
			onSnapshot(
				paymentsRef,
				(querySnapshot) => {
					payments.length = 0;
					querySnapshot.forEach((doc) => {
						payments.push({ id: doc.id, ...doc.data() });
					});
					this.bookingDetailsComponentKey = Date.now();
				},
				(error) => {
					console.log(error);
				}
			);
			return payments;
		},
	},
	mounted() {
		this.$store.commit("setPageTitle", "Bookings");
	},
};
</script>
