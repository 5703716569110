<template>
	<div>
		<div v-if="Array.isArray(activeOrderTravellers) && activeOrderTravellers.length > 0">
			<ul class="list-group list-group-flush mb-4">
				<li class="list-group-item" v-for="traveller in activeOrderTravellers" :key="traveller.id">
					<p>
					<address>
						<strong>{{ traveller.fname?.toUpperCase() }} {{ traveller.lname?.toUpperCase() }}</strong>
						<br />
						Email: {{ traveller.email }}
						<br />
						Phone: {{ traveller.phone }}
						<br />
					</address>
					<span class="small text-muted">T-shirt size:</span>
					{{ traveller.tshirt_size?.toUpperCase() }}
					<br />
					<span class="small text-muted">Notes: <br /></span>
					{{ traveller.notes || "---" }}
					</p>
					<p>
						<span class="small text-muted">Costume: <br /></span>
						{{ traveller.costume?.name?.toUpperCase() || "None" }}

						<span v-if="traveller.costumeData !== null && Object.keys(traveller.costumeData).length > 0">
							<br /><span
								v-for="key in Object.keys(traveller.costumeData).filter((k) => traveller.costumeData[k] !== null)"
								:key="key">
								&nbsp; &bull; {{ key }} : {{ traveller.costumeData[key]?.toUpperCase() }} <br />
							</span>
						</span>
						<span v-if="traveller.addons !== null && Object.keys(traveller.addons).length > 0">
							<span class="small text-muted">Addons: <br /></span>
							<span v-for="key in Object.keys(traveller.addons)" :key="key">
								&nbsp; &bull; {{ traveller.addons[key]?.name }} <br />
							</span>
						</span>

					</p>
					<p>
						<span class="small text-dark">Order total: {{ formatCurrency(traveller.order_total) }}</span> <br />
						<span class="small text-dark">Order discount: {{ formatCurrency(traveller.order_discount) }}</span>
						<br />
						<span class="small text-dark">Order payment: {{ formatCurrency(traveller.order_payments) }}</span>
						<br />
						<span class="small text-danger">Order balance: {{ formatCurrency(parseFloat(traveller.order_total -
							traveller.order_discount - traveller.order_payments)) }}</span>
					</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { formatDate, formatCurrency } from "../../utils";
export default {
	name: "bookings-travellers",
	components: {},
	props: {
		activeOrderTravellers: Array,
	},
	computed: {
		paymentAmount() {
			return this.$store.state.paymentAmount;
		},
	},
	data() {
		return {
			isBusy: false,
			paymentMethodComponentKey: null,
			formatCurrency,
			formatDate,
		}
	},

}
</script>
