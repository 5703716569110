<template>
  <div>
    <div v-if="Array.isArray(activeOrderPayments) && activeOrderPayments.length > 0">
      <div class="row">
        <div class="col-12">
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item small"
              v-for="trxn in orderBy(activeOrderPayments, 'trxn_date', 'desc')"
              :key="trxn.id"
            >
              &bull; {{ formatDate(trxn.trxn_date, "MMM dd yyyy") }} -
              {{ trxn.payment_method }} - {{ formatCurrency(trxn.amount) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-12 my-5" align="center">
        <em>No payments found...</em>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import { formatDate, formatCurrency } from "../../utils";
export default {
  name: "bookings-payments",
  components: {},
  props: {
    activeOrderPayments: Array,
  },
  setup() {},
  computed: {
    paymentAmount() {
      return this.$store.state.paymentAmount;
    },
  },
  data() {
    return {
      formatCurrency,
      formatDate,
      orderBy,
      isBusy: false,
      paymentMethodComponentKey: null,
    };
  },
  methods: {
    btnAddPaymentClick() {
      this.paymentMethodComponentKey = Date.now();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", "Bookings");
  },
};
</script>
